<template>
  <div class="inner-title">
    <div v-if="icon" class="inner-title_icon">
      <img :src="`/innertitle-icons/${ icon }`" :srcset="iconSrcset" alt="">
    </div>
    <div class="inner-title_text">
      <p>
        <slot name="title" />
      </p>
      <span><slot name="subtitle" /></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    iconX2: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconSrcset () {
      if (this.iconX2) {
        return `/innertitle-icons/${this.icon} 1x, /innertitle-icons/${this.iconX2} 2x`
      }
      return null
    },
  },
}
</script>

<style lang="less">
.inner-title {
  display: flex;
  align-items: center;

  &_icon {
    flex-shrink: 0;
    margin-right: 30px;

    img {
      max-height: 100px;
      width: auto;

      @media screen and (max-width: 768px) {
        max-height: 50px;
      }
    }
  }

  &_text {
    p {
      font-family: 'Aqum', Arial, sans-serif;
      font-weight: 500;
      font-size: 30px;
      color: black;

      @media screen and (max-width: 768px) {
        font-size: 30px;
      }
    }

    span {
      font-family: 'Roboto', Arial, sans-serif;
      font-weight: 300;
      font-size: 20px;
      color: #686868;
      line-height: 1.5em;
    }
  }
}
</style>
