<template>
  <div class="card-group">
    <template v-for="(val, i) in items">
      <div v-if="excludeId(val.id)" :key="`recomrolls-${ i }`" class="card-group_item">
        <Card :data="val" :type="type" />
      </div>
    </template>
  </div>
</template>

<script>
import Card from './Card'

export default {
  components: { Card },
  props: {
    items: {
      type: Array,
      default () {
        return []
      },
    },
    type: {
      type: String,
    },
    collectionIds: {
      type: Array,
      default () { return null },
    },
  },
  methods: {
    excludeId (id) {
      return this.collectionIds
        ? !this.collectionIds.includes(id)
        : true
    },
  },
}
</script>

<style lang="less">
.card-group {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;

	&_item {
		max-width: calc(100% / 3);
		display: flex;
		flex-basis: calc(100% / 3);
		flex-grow: 0;
		margin-bottom: 22px;

		@media screen and (max-width: 1200px) {
			max-width: calc(100% / 3);
			flex-basis: calc(100% / 3);
		}

		@media screen and (max-width: 992px) {
			max-width: calc(100% / 2);
			flex-basis: calc(100% / 2);
		}

		@media screen and (max-width: 768px) {
			max-width: 100%;
			flex-basis: 100%;
		}

		&:not(:nth-child(3n + 3)) > .card {
			margin-right: 18px;

			@media screen and (max-width: 1200px) { margin-right: 15px; }
			@media screen and (max-width: 992px) { margin-right: 0; }
		}

		&:not(:nth-child(2n + 2)) > .card {
			@media screen and (max-width: 992px) { margin-right: 15px; }
			@media screen and (max-width: 768px) { margin-right: 0; }
		}
	}
}
</style>
