<template>
  <div class="card_slider">
    <client-only placeholder="Загрузка...">
      <swiper ref="swiper" :options="sliderOptions">
        <template v-for="(val, i) in items">
          <swiper-slide v-show="excludeId(val.id)" :key="`cardslider-${ i }`">
            <Card
              :data="val"
              :type="type"
            />
          </swiper-slide>
        </template>
        <div id="recomRolls" slot="pagination" class="swiper-pagination" />
      </swiper>
    </client-only>
  </div>
</template>

<script>
export default {
  components: {
    Card: () => import('./Card.vue'),
  },
  props: {
    items: {
      type: Array,
      default () {
        return []
      },
    },
    type: {
      type: String,
    },
    sliderOptions: {
      type: Object,
      default () {
        return {
          slidesPerView: 2,
          spaceBetween: 22,
          breakpoints: {
            768: { slidesPerView: 2.2 },
            0: { slidesPerView: 1.2 },
          },
        }
      },
    },
    collectionIds: {
      type: Array,
      default () { return null },
    },
  },
  methods: {
    excludeId (id) {
      return this.collectionIds
        ? !this.collectionIds.includes(id)
        : true
    },
  },
}
</script>

<style lang="less">
.card_slider {
	max-width: 100%;
	width: 100%;
	display: flex;

	.swiper {
		&-container {
			width: 100%;
			padding-bottom: 45px;
			margin-bottom: -45px;
		}

		&-slide {
			height: auto;

			.card { height: 100%; }
		}
	}
}
</style>
