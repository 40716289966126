<template>
  <div class="breadcrumbs">
    <div
      v-for="(link, i) in breadcrumbsLinks"
      :key="`brdcrmbs-${ i }`"
      @click="goToRoute(link.path, (link.prevent) ? link.prevent : false)"
      v-html="link.name + getSlash(i)"
      class="breadcrumbs_level"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: null,
    },
    parentCategory: {
      type: Object,
      default: () => { return {} },
    },
    currentName: String,
  },
  computed: {
    breadcrumbsLinks () {
      let crumbs = [{ name: 'Главная', path: '/' }, { name: 'Меню', path: '/menu/' }]

      switch (this.type) {
        case 'rolls':
          crumbs = [ ...crumbs,
            { name: 'Роллы', path: '/menu/rolls/' },
            { name: this.currentName, path: this.$route.path, prevent: true },
          ]
          break
        case 'menuItem':
          crumbs = [ ...crumbs,
            this.parentCategory,
            { name: this.currentName, path: this.$route.path, prevent: true },
          ]
          break
        case 'pizza':
          crumbs = [ ...crumbs,
            { name: 'Пицца', path: '/menu/pizza/' },
            { name: this.currentName, path: this.$route.path, prevent: true },
          ]
          break
        case 'combo':
          crumbs = [ ...crumbs,
            { name: 'Комбо-наборы', path: '/menu/combo/' },
            { name: this.currentName, path: this.$route.path, prevent: true },
          ]
          break
        case 'news-single':
          crumbs = [
            { name: 'Главная', path: '/' },
            { name: 'Новости', path: '/news' },
            { name: this.currentName, path: this.$route.path, prevent: true },
          ]
          break
        case 'security':
          crumbs = [
            { name: 'Главная', path: '/' },
            { name: 'Доставка', path: '/delivery' },
            { name: this.currentName, path: this.$route.path, prevent: true },
          ]
          break
        default:
          crumbs = [
            { name: 'Главная', path: '/' },
            { name: this.currentName, path: this.$route.path, prevent: true },
          ]
          break
      }

      return crumbs
    },
  },
  methods: {
    goToRoute (link, prevent) {
      if (!prevent) { this.$router.push(link) }
    },
    getSlash (i) {
      return (i !== this.breadcrumbsLinks.length - 1) ? '&nbsp;/&nbsp;' : ''
    },
  },
}
</script>

<style lang="less">
	.breadcrumbs {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&_level {
			font-family: 'Roboto', Arial, sans-serif;
			font-weight: 300;
			font-size: 14px;
			cursor: pointer;
		}
	}
</style>
