<template>
  <div class="recomended-slider">
    <CardSlider :items="recomended" :sliderOptions="sliderOptions" />
  </div>
</template>

<script>
import CardSlider from './CardSlider'

export default {
  components: { CardSlider },
  props: {
    sliderOptions: {
      type: Object,
      default () {
        return {
          slidesPerView: 3,
          spaceBetween: 27,
          pagination: {
            el: '#recomRolls',
            type: 'bullets',
          },
          breakpoints: {
            992: { slidesPerView: 3 },
            768: {
              slidesPerView: 2.2,
              spaceBetween: 27,
            },
            0: {
              slidesPerView: 1.5,
              spaceBetween: 21,
            },
          },
        }
      },
    },
  },
  data () {
    return {
      recomended: [],
    }
  },
  created () {
    this.$store.dispatch('menu/fetchCollections', { target: 'recomended', type: 'desktop' }).then((res) => {
      this.recomended = res.items[0].menuItems
    }).catch(err => err)
  },
}
</script>

<style lang="less">
.recomended-slider {
	.swiper {
		&-container {
			position: relative;
			padding-bottom: 30px;
		}

		&-wrapper {
			position: static;
		}

		&-pagination {
			position: absolute;
			bottom: -33px;
			left: 50%;
			transform: translateX(-50%);

			&-bullet {
				width: 13px;
				height: 7px;
				background-color: #DEDEDE;
				border-radius: 20px;

				&:not(:last-child) {
					margin-right: 7px;
				}

				&-active {
					width: 28px;
					background-color: #003CFF;
				}
			}
		}
	}
}
</style>
