<template>
  <div class="recomended_slider">
    <CardSlider :items="items" :collectionIds="collectionIds" :type="type" />
  </div>
</template>

<script>
import CardSlider from './CardSlider'

export default {
  components: { CardSlider },
  props: {
    items: {
      type: Array,
      default () {
        return []
      },
    },
    type: {
      type: String,
    },
    collectionIds: {
      type: Array,
      default () { return null },
    },
  },
}
</script>
